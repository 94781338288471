import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="page-404">
      <div className="page-404__text-container">
        <h1 className="page-404__title">Page Not Found</h1>
        <p className="page-404__copy">We're sorry, the link you clicked is either broken,<br />or the page has been moved.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
